import { createApp, h } from "vue"
import { createInertiaApp, Head, Link } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "ziggy-vue"
import route from "ziggy"
import store from "./stores/store"
import * as Sentry from "@sentry/vue"

import _ from "lodash"
import dayjs from "dayjs"
import utcPlugin from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import todayPlugin from "dayjs/plugin/isToday"
import advancedFormat from "dayjs/plugin/advancedFormat"
import timezonePlugin from "dayjs/plugin/timezone"
import localizedFormat from "dayjs/plugin/localizedFormat"
import axios from "axios"

// PrimeVue
import PrimeVue from "primevue/config"

// Components
import AutoComplete from "primevue/autocomplete"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import Avatar from "primevue/avatar"
import AvatarGroup from "primevue/avatargroup"
import Badge from "primevue/badge"
import BadgeDirective from "primevue/badgedirective"
import Button from "primevue/button"
import Calendar from "primevue/calendar"
import Card from "primevue/card"
import Checkbox from "primevue/checkbox"
import Chip from "primevue/chip"
import BlockUI from "primevue/blockui"
import Column from "primevue/column"
import ConfirmDialog from "primevue/confirmdialog"
import DynamicDialog from "primevue/dynamicdialog"
import DialogService from "primevue/dialogservice"

import ConfirmationService from "primevue/confirmationservice"
import CascadeSelect from "primevue/cascadeselect"

import DataTable from "primevue/datatable"
import Dialog from "primevue/dialog"
import Divider from "primevue/divider"
import Dropdown from "primevue/dropdown"
import FileUpload from "primevue/fileupload"
import Image from "primevue/image"
import InlineMessage from "primevue/inlinemessage"
import InputMask from "primevue/inputmask"
import InputNumber from "primevue/inputnumber"
import InputSwitch from "primevue/inputswitch"
import InputText from "primevue/inputtext"
import Listbox from "primevue/listbox"
import Menu from "primevue/menu"
import Menubar from "primevue/menubar"
import Message from "primevue/message"
import MultiSelect from "primevue/multiselect"
import OverlayPanel from "primevue/overlaypanel"
import Paginate from "./components/Paginate/Paginate.vue"
import Required from "./components/Required.vue"
import Blocker from "./components/Blocker.vue"
import Panel from "primevue/panel"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import RadioButton from "primevue/radiobutton"
import Ripple from "primevue/ripple"
import ScrollPanel from "primevue/scrollpanel"
import StyleClass from "primevue/styleclass"
import Tag from "primevue/tag"
import Textarea from "primevue/textarea"
import Toast from "primevue/toast"
import ToastService from "primevue/toastservice"
import Toolbar from "primevue/toolbar"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Tooltip from "primevue/tooltip"
import ToggleButton from "primevue/togglebutton"
import Tree from "primevue/tree"
import TreeSelect from "primevue/treeselect"
import TreeTable from "primevue/treetable"
import InputGroup from "primevue/inputgroup"
import InputGroupAddon from "primevue/inputgroupaddon"
import MinimalLayout from "./layouts/MinimalLayout.vue"
import AdminLayout from "./layouts/AdminLayout.vue"

import "primevue/resources/themes/lara-light-green/theme.css"

import "../css/styles.scss"

window._ = _

window.dayjs = dayjs
window.dayjs.extend(relativeTime)
window.dayjs.extend(utcPlugin)
window.dayjs.extend(duration)
window.dayjs.extend(todayPlugin)
window.dayjs.extend(advancedFormat)
window.dayjs.extend(timezonePlugin)
window.dayjs.extend(localizedFormat)

axios.interceptors.request.use((config) => {
  // Modify the URL only if it starts with "http://"
  if (config.url.startsWith("http://")) {
    config.url = config.url.replace(/^http:/, "https:")
  }

  return config
})
window.axios = axios
// This allows laravel to pick this up as an ajax request (Rather than inertia)
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

createInertiaApp({
  resolve: async (name) => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob("./pages/**/*.vue")),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .directive("tooltip", Tooltip)
      .directive("ripple", Ripple)
      .directive("badge", BadgeDirective)
      .directive("styleclass", StyleClass)

      .component("Link", Link)
      .component("Head", Head)

      .component("Accordion", Accordion)
      .component("AccordionTab", AccordionTab)
      .component("AutoComplete", AutoComplete)
      .component("Avatar", Avatar)
      .component("AvatarGroup", AvatarGroup)
      .component("Badge", Badge)
      .component("Button", Button)
      .component("Calendar", Calendar)
      .component("Card", Card)
      .component("CascadeSelect", CascadeSelect)
      .component("Checkbox", Checkbox)
      .component("Chip", Chip)
      .component("BlockUI", BlockUI)
      .component("Column", Column)
      .component("ConfirmDialog", ConfirmDialog)
      .component("DataTable", DataTable)
      .component("Dialog", Dialog)
      .component("Divider", Divider)
      .component("Dropdown", Dropdown)
      .component("DynamicDialog", DynamicDialog)
      .component("FileUpload", FileUpload)
      .component("Image", Image)
      .component("InlineMessage", InlineMessage)
      .component("InputMask", InputMask)
      .component("InputNumber", InputNumber)
      .component("InputSwitch", InputSwitch)
      .component("InputText", InputText)
      .component("Listbox", Listbox)
      .component("Menu", Menu)
      .component("Menubar", Menubar)
      .component("Message", Message)
      .component("MultiSelect", MultiSelect)
      .component("OverlayPanel", OverlayPanel)
      .component("Panel", Panel)
      .component("Password", Password)
      .component("ProgressBar", ProgressBar)
      .component("RadioButton", RadioButton)
      .component("ScrollPanel", ScrollPanel)
      .component("TabView", TabView)
      .component("TabPanel", TabPanel)
      .component("Tag", Tag)
      .component("Textarea", Textarea)
      .component("Toast", Toast)
      .component("Toolbar", Toolbar)
      .component("ToggleButton", ToggleButton)
      .component("Tree", Tree)
      .component("TreeSelect", TreeSelect)
      .component("TreeTable", TreeTable)
      .component("InputGroupAddon", InputGroupAddon)
      .component("InputGroup", InputGroup)

      .component("MinimalLayout", MinimalLayout)
      .component("AdminLayout", AdminLayout)
      .component("Paginate", Paginate)
      .component("Required", Required)
      .component("Blocker", Blocker)

      //https://github.com/primefaces/primevue/issues/1231#issuecomment-836835754
      .component("router-link", {})

      .mixin({ methods: { route } })
      .use(plugin)
      .use(PrimeVue, { ripple: true })
      .use(ConfirmationService)
      .use(DialogService)
      .use(ToastService)
      .provide(`$axios`, axios)
      .use(store)
      .use(ZiggyVue)
      .mount(el)

    try {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN_PUBLIC,
        ignoreErrors: ["fbq is not defined"],
      })
    } catch (e) {}

    return app
  },
})
