<script setup>
import { router, usePage, Head } from "@inertiajs/vue3"
import { onMounted, ref } from "vue"
import { useToast } from "primevue/usetoast"
import route from "ziggy"
import dayjs from "dayjs"
const toast = useToast()

const environment = import.meta.env.VITE_APP_ENV

const bengieMenu = usePage().props.bengieMenu
const user = usePage().props.user

const searchQuery = ref(null)
const performSearch = () => {
  if (!searchQuery.value) {
    return
  }
  router.get(
    route("admin.bookings.index", {
      query: searchQuery.value,
    }),
  )
}

defineProps({
  description: String,
  heading: String,
  title: String,
})

const onMenuItemClicked = (originalEvent) => {
  router.get(originalEvent.item.inertiaUrl)
}

function addCallbackRecursive(items, callback) {
  return items.map((item) => {
    const newItem = { ...item }
    if (newItem.hasOwnProperty("inertiaUrl")) {
      newItem.command = callback
    }
    if (newItem.items) {
      newItem.items = addCallbackRecursive(newItem.items, callback)
    }
    return newItem
  })
}

const items = addCallbackRecursive(bengieMenu, onMenuItemClicked)

onMounted(() => {
  if (usePage().props.flash.bannerMessage) {
    toast.add({
      severity: usePage().props.flash.bannerSeverity,
      detail: usePage().props.flash.bannerMessage,
      life: 10000,
    })
  }
})

const userMenu = [
  {
    label: "Logout",
    icon: "pi pi-power-off",
    command: () => {
      router.post(route("admin.logout"))
    },
  },
]

const profileClick = () => {
  router.get(route("admin.users.user.edit", user))
}

const userInitials = user.name
  .split(" ")
  .map((n) => n[0])
  .join("")

const userNotifications = ref([])
const mapNotifications = (data) => {
  userNotifications.value = data.map((n) => {
    return {
      label: n.message,
      data: n,

      command: (event) => {
        if (event.originalEvent.target.tagName.toLowerCase() !== "i") {
          router.get(
            route("admin.bookings.booking.index", {
              booking: n.model.reservations[0].booking.hash_id,
            }),
          )
        }
      },
    }
  })
}

axios.get(route("admin.api.admin.user-notifications")).then((r) => {
  if (r.data.success) {
    mapNotifications(r.data.notifications)
  }
})

const menu = ref()
const notifications = ref()
const toggleMenu = (event) => {
  menu.value.toggle(event)
}
const toggleNotifications = (event) => {
  notifications.value.toggle(event)
}

const clear = (id) => {
  axios
    .get(
      route("admin.user-notifications.user-notification.clear", {
        userNotification: id,
      }),
    )
    .then((r) => {
      if (r.data.success) {
        mapNotifications(r.data.notifications)
      }
    })
}

const clearAll = () => {
  axios.get(route("admin.user-notifications.clear-all")).then((r) => {
    if (r.data.success) {
      mapNotifications(r.data.notifications)
    }
  })
}
</script>

<template>
  <Head :title="title ? title + ' | Bengie' : 'Bengie'" />
  <Toast />
  <ConfirmDialog />
  <div class="layout-container layout-light layout-dark-menu layout-horizontal p-input-filled bg-gray-200">
    <div
      v-if="environment && environment !== 'production'"
      class="w-full text-center text-white bg-red-500 text-lg uppercase font-bold">
      {{ environment }}
    </div>
    <Menubar
      class="border-left-none border-top-none border-right-none border-noround bg-gray-100 border-bottom border-gray-300"
      :model="items">
      <template #start>
        <img src="/assets/bengie-logo-no-text.png" alt="Bengie" class="w-2rem m-1" />
      </template>
      <template #end>
        <div class="flex">
          <InputGroup class="mr-2">
            <InputText v-model="searchQuery" placeholder="Search" @keyup.enter="performSearch" />
            <Button icon="pi pi-search" @click="performSearch" :disabled="!searchQuery" />
          </InputGroup>
          <span class="mx-1 relative">
            <Badge
              v-if="userNotifications.length > 0"
              :value="userNotifications.length"
              severity="danger"
              class="absolute left-50 z-3"></Badge>
            <Button icon="pi pi-bell" plain text @click="toggleNotifications" />
          </span>
          <Button icon="pi pi-user" plain text @click="toggleMenu" />
          <Menu
            ref="notifications"
            id="overlay_menu"
            :model="userNotifications"
            :popup="true"
            class="mt-2"
            :pt="{
              root: { class: 'w-22rem' },
              content: { class: 'p-2 cursor-pointer' },
            }">
            <template #start>
              <div v-if="userNotifications.length === 0">
                <p class="pl-4">You have no new notifications</p>
              </div>
            </template>
            <template #item="{ item, props }">
              <div class="flex justify-content-between">
                <p class="text-xs">Ref: {{ item.data.model.hash_id }}</p>
                <p class="text-xs">{{ dayjs(item.data.created_at).fromNow() }}</p>
              </div>
              <div class="flex justify-content-between align-items-center">
                <div class="mr-2">
                  <p>{{ item.label }}</p>
                </div>
                <div>
                  <i
                    class="pi pi-delete-left pr-2 text-primary text-lg"
                    v-tooltip.bottom="'Clear notification'"
                    @click="clear(item.data.id)"></i>
                </div>
              </div>
            </template>
            <template #end>
              <div class="flex justify-content-end">
                <Button label="Clear all" icon="pi pi-delete-left" class="absolute top-100 mt-2" @click="clearAll" />
              </div>
            </template>
          </Menu>
          <Menu ref="menu" id="overlay_menu" :model="userMenu" :popup="true" class="mt-2">
            <template #start>
              <button
                @click="profileClick"
                class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                <Avatar :label="userInitials" class="mr-2 bg-primary" shape="circle" />
                <div class="flex flex-column align">
                  <span class="font-bold">{{ user.name }}</span>
                  <span class="text-sm">{{ user.role }}</span>
                </div>
              </button>
            </template>
          </Menu>
        </div>
      </template>
    </Menubar>

    <div class="p-3">
      <slot name="header">
        <div v-if="(heading ?? title) || $slots.heading" class="mx-auto">
          <div class="flex align-content-center align-items-center pr-0">
            <div class="min-w-0 flex-1">
              <slot name="heading">
                <h1 class="text-2xl font-bold">{{ heading ?? title }}</h1>
              </slot>
            </div>
            <div v-if="$slots.headingAside" class="flex">
              <slot name="headingAside" />
            </div>
          </div>
        </div>
      </slot>
      <div class="mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<style>
.p-submenu-list {
  z-index: 999;
}
</style>
