<script setup>
import { router } from "@inertiajs/vue3"

const props = defineProps({
  links: Array,
  handleNavigation: {
    type: Function,
    required: false,
  },
})

const navigate = (url) => {
  if (props.handleNavigation) {
    props.handleNavigation(url)
  } else {
    router.get(url)
  }
}
</script>

<template>
  <div class="flex justify-content-center gap-2 p-2">
    <Button
      v-for="link in links"
      plain
      :class="{ 'border-1 border-teal-300': link.active }"
      text
      v-html="link.label"
      @click="navigate(link.url)" />
  </div>
</template>
