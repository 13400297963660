import dayjs from "dayjs"
import route from "ziggy"

export default class Reservation {
  constructor(data, store) {
    this.store = store

    this.reservationData = {}
    this.searchParams = {
      when: null,
      venue_id: null,
      activity_id: null,
      guests: null,
      duration: null,
      ignore_cache: false,
    }
    this.searchOptions = [
      {
        key: "ignore_cache",
        label: "Ignore Cache",
        command: () => {
          this.searchParams.ignore_cache = !this.searchParams.ignore_cache
        },
      },
    ]
    this.selectedPackageId = null
    this.selectedPackageTime = null
    this.price = null
    this.packageTable = null
    this.hasSearched = false
    this.menu = false

    this.load(data)
  }

  get cart() {
    return this.reservationData.cart
  }

  get menuItems() {
    return this.reservationData.menuItems
  }

  get products() {
    return this.reservationData.products
  }

  get info() {
    return this.reservationData.info
  }

  get addons() {
    return this.reservationData.addons
  }

  get id() {
    return this.reservationData.data.id
  }

  get hash_id() {
    return this.reservationData.data.hash_id
  }

  get actual_spend() {
    return this.reservationData.data.actual_spend ? parseFloat(this.reservationData.data.actual_spend) : 0
  }

  get service_charge() {
    return this.reservationData.data.service_charge
  }

  get service_charge_percentage() {
    return this.reservationData.data.service_charge_percentage
  }

  get hash() {
    return this.reservationData.data.hash
  }

  get held_until() {
    return this.reservationData.data?.pending_change?.held_until
  }

  get state() {
    return this.reservationData.data.state
  }

  get status() {
    return this.reservationData.data.status
  }

  get venue() {
    return this.reservationData.data.venue
  }

  get when() {
    return this.reservationData.data.when
  }

  get guests() {
    return this.reservationData.data.guests
  }

  get actual_guests() {
    return this.reservationData.data.actual_guests
  }

  get activity() {
    return this.reservationData.data.activity
  }

  get duration() {
    return this.reservationData.data.duration
  }

  get package() {
    return this.reservationData.data.package
  }

  get notes() {
    return this.reservationData.data.notes
  }

  get minimum_spend() {
    return this.reservationData.data.minimum_spend
  }

  get pricing() {
    return this.reservationData.data.pricing
  }

  get date() {
    return this.reservationData.data.date
  }

  get time() {
    return this.reservationData.data.time
  }

  get pending_change() {
    return this.reservationData.data.pending_change
  }

  get reservation_guests() {
    return this.reservationData.data.reservation_guests
  }

  get logo() {
    return this.reservationData.logo
  }

  get function_sheet() {
    return this.reservationData.data.function_sheet
  }

  get areas() {
    return this.reservationData.data.areas
  }

  get pendingOrConfirmed() {
    const data = {
      venue: null,
      activity: null,
      guests: null,
      when: null,
      package: null,
      minimum_spend: null,
      service_charge: null,
      service_charge_percentage: null,
      price: null,
      state: null,
      duration: null,
    }

    if (this.hasPendingPackage() && this.pending_change.venue) {
      data.venue = this.pending_change.venue
    } else if (this.venue) {
      data.venue = this.venue
    }

    if (this.hasPendingPackage() && this.pending_change.activity) {
      data.activity = this.pending_change.activity
    } else if (this.activity) {
      data.activity = this.activity
    }

    if (this.hasPendingPackage() && this.pending_change.guests) {
      // guests are incremental
      data.guests = Number(this.guests) + this.pending_change.guests
    } else if (this.guests) {
      data.guests = this.guests
    }

    if (this.hasPendingPackage() && this.pending_change.when) {
      data.when = this.pending_change.when
    } else if (this.when) {
      data.when = this.when
    }

    if (this.hasPendingPackage() && this.pending_change.package) {
      data.package = this.pending_change.package
    } else if (this.package) {
      data.package = this.package
    }

    if (this.hasPendingPackage() && this.pending_change.minimum_spend) {
      data.minimum_spend = this.pending_change.minimum_spend
    } else if (this.minimum_spend) {
      data.minimum_spend = this.minimum_spend
    }

    if (this.hasPendingPackage() && this.pending_change.service_charge) {
      data.service_charge = this.pending_change.service_charge
    } else if (this.service_charge) {
      data.service_charge = this.service_charge
    }

    if (this.hasPendingPackage() && this.pending_change.service_charge_percentage) {
      data.service_charge_percentage = this.pending_change.service_charge_percentage
    } else if (this.service_charge_percentage) {
      data.service_charge_percentage = this.service_charge_percentage
    }

    if (this.hasPendingPackage() && this.pending_change?.pricing.price) {
      data.price = this.pending_change.pricing.price
    } else if (this.pricing?.price) {
      data.price = this.pricing.price
    }

    if (this.hasPendingPackage() && this.pending_change.state) {
      data.state = this.pending_change.state
    } else if (this.state) {
      data.state = this.state
    }

    if (this.hasPendingPackage() && this.pending_change?.duration) {
      data.duration = this.pending_change.duration
    } else if (this.duration) {
      data.duration = this.duration
    }

    return data
  }

  get preOrderChoices() {
    if (!this.package) {
      return []
    }
    return this.package.package_components
      .filter((p) => p.type === "pre_order")
      .map((p) => {
        return {
          id: p.id,
          name: p.name,
          pre_orders: p.pre_orders.map((pop) => {
            return {
              id: pop.pre_order_product.id,
              name: pop.pre_order_product.name,
            }
          }),
        }
      })
  }

  get covers_ignored() {
    return this.reservationData.data.ignore_reservation_covers
  }

  set when(value) {
    this.reservationData.data.when = value
  }

  load(reservationData) {
    this.reservationData = reservationData

    // Datetimes from Bengie are in UTC
    this.when = this.date ? dayjs.tz(this.date + " " + this.time, "UTC") : null

    if (this.reservationData.data.pending_change && this.reservationData.data.pending_change.date) {
      this.pending_change.when = this.pending_change.date
        ? dayjs.tz(
            this.reservationData.data.pending_change.date + " " + this.reservationData.data.pending_change.time,
            "UTC",
          )
        : null
    }

    this.resetSearchParams()
  }

  hasGuests() {
    return this.reservation_guests && this.reservation_guests.length > 0
  }

  canBeSaved() {
    return this.hasPendingChange() && this.isEditable() && this.store.getters.isSalesService
  }

  canCancelPendingChanges() {
    return this.hasPendingChange() && this.isEditable()
  }

  canBeReserved() {
    return (
      this.pendingOrConfirmed.package &&
      this.pendingOrConfirmed.state !== "reserved" &&
      this.isEditable() &&
      this.store.getters.isSalesService
    )
  }

  canBeConfirmed() {
    return (
      this.pendingOrConfirmed.package &&
      this.pendingOrConfirmed.state !== "confirmed" &&
      this.isEditable() &&
      this.store.getters.isSalesService
    )
  }

  canBeUnreserved() {
    return (
      this.pendingOrConfirmed.package &&
      this.pendingOrConfirmed.state !== "unreserved" &&
      this.isEditable() &&
      this.store.getters.isSalesService
    )
  }

  canSearch() {
    return this.packageTable === null
  }

  hasSearchResults() {
    return this.packageTable !== null
  }

  canSync() {
    return ["reserved", "confirmed"].includes(this.pendingOrConfirmed.state)
  }

  isEditable() {
    return this.reservationData.isEditable
  }

  hasPendingAddons() {
    return (
      this.reservationData.addons &&
      this.reservationData.addons.length &&
      this.reservationData.addons.filter((addon) => addon.is_pending).length > 0
    )
  }

  hasPendingPackage() {
    return this.pending_change !== undefined && this.pending_change !== null
  }

  hasPendingChange() {
    return this.hasPendingPackage() || this.hasPendingAddons()
  }

  hasConfirmedData() {
    return this.package
  }

  hasPassed() {
    // @sunil : better use teh status!
    return dayjs(this.when).isBefore(dayjs())
  }

  canBeFinalised() {
    // We have a pending change record, and the pending change is reserved
    if (
      this.hasPendingPackage() &&
      (this.pending_change.state === "reserved" || this.pending_change.state === "unreserved")
    ) {
      return true
    }

    // We have a pending change record, without a package (state === null), and the main reservation is confirmed
    if (this.hasPendingPackage() && this.pending_change.state === null && this.reservationData.state === "confirmed") {
      return true
    }

    //we are a sales service
    if (this.store.state.financeData.totalDue !== 0) {
      return true
    }
  }

  canBeReserved() {
    return (
      this.pendingOrConfirmed.package &&
      this.pendingOrConfirmed.state !== "reserved" &&
      this.isEditable() &&
      this.store.getters.isSalesService
    )
  }

  canBeConfirmed() {
    return (
      this.pendingOrConfirmed.package &&
      this.pendingOrConfirmed.state !== "confirmed" &&
      this.isEditable() &&
      this.store.getters.isSalesService
    )
  }

  canBeUnreserved() {
    return (
      this.pendingOrConfirmed.package &&
      this.pendingOrConfirmed.state !== "unreserved" &&
      this.isEditable() &&
      this.store.getters.isSalesService
    )
  }

  canSearch() {
    return this.packageTable === null
  }

  hasSearchResults() {
    return this.packageTable !== null
  }

  canSync() {
    return ["reserved", "confirmed"].includes(this.pendingOrConfirmed.state)
  }

  isEditable() {
    return this.reservationData.isEditable
  }

  hasPendingAddons() {
    return (
      this.reservationData.addons &&
      this.reservationData.addons.length &&
      this.reservationData.addons.filter((addon) => addon.is_pending).length > 0
    )
  }

  hasPendingPackage() {
    return this.pending_change !== undefined && this.pending_change !== null
  }

  hasPendingChange() {
    return this.hasPendingPackage() || this.hasPendingAddons()
  }

  hasConfirmedData() {
    return this.package
  }

  hasPassed() {
    // @sunil : better use teh status!
    return dayjs(this.when).isBefore(dayjs())
  }

  canBeFinalised() {
    // We have a pending change record, and the pending change is reserved
    if (
      this.hasPendingPackage() &&
      (this.pending_change.state === "reserved" || this.pending_change.state === "unreserved")
    ) {
      return true
    }

    // We have a pending change record, without a package (state === null), and the main reservation is confirmed
    if (this.hasPendingPackage() && this.pending_change.state === null && this.reservationData.state === "confirmed") {
      return true
    }

    //we are a sales service
    if (this.store.state.financeData.totalDue !== 0) {
      return true
    }

    return this.hasPendingAddons() // @sunil Or here..
  }

  resetSearchParams() {
    const nextDate = dayjs().add(2, "day").set("hour", 14).set("minute", 0).set("second", 0).toDate()
    this.searchParams.when = this.pendingOrConfirmed.when ? dayjs(this.pendingOrConfirmed.when).toDate() : nextDate

    this.searchParams.guests = this.pendingOrConfirmed.guests ? this.pendingOrConfirmed.guests : 2
    const defaultDuration = this.store.state.durations.length > 0 ? this.store.state.durations[0].duration : null
    this.searchParams.duration = this.pendingOrConfirmed.duration ? this.pendingOrConfirmed.duration : defaultDuration

    const defaultActivity = this.store.state.activities.length > 0 ? this.store.state.activities[0].id : null
    console.log(defaultActivity)
    this.searchParams.activity_id = this.pendingOrConfirmed.activity?.id
      ? this.pendingOrConfirmed.activity.id
      : defaultActivity

    const defaultVenue = this.store.state.venues.length > 0 ? this.store.state.venues[0].id : null
    this.searchParams.venue_id = this.pendingOrConfirmed.venue?.id ? this.pendingOrConfirmed.venue.id : defaultVenue

    this.packageTable = null
    this.selectedPackageId = null
    this.selectedPackageTime = null
    this.ignore_cache = false
  }

  guestsCompleted() {
    return this.reservation_guests.filter((item) => item.is_coming === true).length
  }

  guestsRemaining() {
    return this.guests - this.guestsCompleted()
  }

  canAddGuests() {
    return this.guestsRemaining() > 0 && this.isEditable()
  }

  search() {
    this.hasSearched = true

    const endpoint = route("admin.reservations.reservation.ajax-search", {
      timezone: this.store.getters.timeZone,
      reservation: this.reservationData.data,
      ...this.searchParams,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint }).then((data) => {
      this.packageTable = data.packageTable
    })
  }

  selectPackage() {
    const parts = this.selectedPackageTime.split(":")
    this.searchParams.when = dayjs(this.searchParams.when)
      .set("hour", Number(parts[0]))
      .set("minute", Number(parts[1]))
      .toDate()

    const endpoint = route("admin.reservations.reservation.select-package", {
      reservation: this.reservationData.data,
      timezone: this.store.getters.timeZone,
      package_id: this.selectedPackageId,
      when: this.searchParams.when,
      guests: this.searchParams.guests,
      venue_id: this.searchParams.venue_id,
      activity_id: this.searchParams.activity_id,
      duration: this.searchParams.duration,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  sync() {
    const endpoint = route("admin.reservations.reservation.sync", {
      reservation: this.reservationData.data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  markAsReserved() {
    const endpoint = route("admin.reservations.reservation.mark-as-reserved", {
      reservation: this.reservationData.data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  markAsUnreserved() {
    const endpoint = route("admin.reservations.reservation.mark-as-unreserved", {
      reservation: this.reservationData.data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  markAsConfirmed() {
    const endpoint = route("admin.reservations.reservation.mark-as-confirmed", {
      reservation: this.reservationData.data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  save(confirmAsWell) {
    const endpoint = route("admin.reservations.reservation.save", {
      reservation: this.reservationData.data,
      confirm: confirmAsWell,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  setMinimumSpend(minimumSpend) {
    const endpoint = route("admin.reservations.reservation.set-minimum-spend", {
      reservation: this.reservationData.data,
      minimum_spend: minimumSpend,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  // Cancel all pending changes
  cancelPendingChanges() {
    const endpoint = route("admin.reservations.reservation.cancel-pending-changes", {
      reservation: this.reservationData.data,
      reason: "Admin Action",
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  cancel() {
    const endpoint = route("admin.reservations.reservation.cancel", {
      reservation: this.reservationData.data,
      reason: "Admin Action",
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  remove() {
    const endpoint = route("admin.reservations.reservation.remove", {
      reservation: this.reservationData.data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  setServiceCharge(data) {
    const endpoint = route("admin.reservations.reservation.set-service-charge", {
      reservation: this.reservationData.data,
      ...data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint }, data)
  }

  // Handle CRUD actions on reservation addons
  updateCreateOrDeleteAddon(data) {
    const endpoint = route("admin.reservations.reservation.addons.update-create-or-delete-addon", {
      reservation: this.reservationData.data,
      ...data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  // Add a one off custom product to a reservation
  addCustomProduct(data) {
    const endpoint = route("admin.reservations.reservation.add-custom-product", {
      reservation: this.reservationData.data,
      ...data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  setFunctionSheet(function_sheet) {
    const endpoint = route("admin.reservations.reservation.set-function-sheet", {
      reservation: this.reservationData.data,
      function_sheet,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  // Save reservation guests
  updateOrCreateGuest(data) {
    const endpoint = route("admin.reservations.reservation.guests.create-guest-and-pre-order", {
      reservation: this.reservationData.data,
      ...data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  removeGuest(guest) {
    const endpoint = route("admin.reservations.reservation.guests.reservation-guest.remove", {
      reservation: this.reservationData.data,
      guest,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  markAsComing(guest) {
    const endpoint = route("admin.reservations.reservation.guests.reservation-guest.mark-as-coming", {
      reservation: this.reservationData.data,
      guest,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  markAsNotComing(guest) {
    const endpoint = route("admin.reservations.reservation.guests.reservation-guest.mark-as-not-coming", {
      reservation: this.reservationData.data,
      guest,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  setActualSpend(actual_spend) {
    const endpoint = route("admin.reservations.reservation.set-actual-spend", {
      reservation: this.reservationData.data,
      actual_spend,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  sendTransactionalEmail(transactional_email_id) {
    const endpoint = route("admin.reservations.reservation.send-transactional-email", {
      reservation: this.reservationData.data,
      transactional_email_id,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  setPricing(data) {
    const endpoint = route("admin.reservations.reservation.set-pricing", {
      reservation: this.reservationData.data,
      ...data,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }

  getProduct(product_id) {
    const endpoint = route("admin.reservations.reservation.get-product", {
      reservation: this.reservationData.data,
      product_id,
    })
    return this.store.dispatch("doAction", { endpoint: endpoint })
  }
}
