<script setup>
import { Head, usePage } from "@inertiajs/vue3"
import { computed } from "vue"

const props = defineProps({
  description: String,
  heading: String,
  title: String,
})

const pageTitle = computed(() => props.heading || props.title)
</script>
<template>
  <Head :title="pageTitle">
    <meta v-if="description" name="description" :content="description" />
  </Head>

  <div class="surface-0 bg-gray-100">
    <div class="flex align-items-center justify-content-between flex-column h-screen">
      <div class="flex flex-column align-items-center justify-content-center md:w-5 w-8 h-full text-center py-6 px-4">
        <img alt="Super fantastic Bengie logo" src="/assets/bengie-logo.png" class="w-10rem mb-4" />
        <div class="border-1 border-gray-300 border-round-2xl w-full p-6 bg-white">
          <div class="text-3xl font-bold mb-4">{{ heading }}</div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
